import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { kakaoJSkey, KakaoLoginAPI } from '../config';
import EventTracker from '../components/EventTracker';
import loadingDefault from '../assets/images/loading_default.gif';

const LoginHandler = (props) => {
    const location = useLocation();
    const response = location.state?.response;

    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const [profileID, setProfileID] = useState('');
    const [eventTracked, setEventTracked] = useState(false);

    const loginEventParams = {
        pageName: "signup",
        pageCode: "p0002",
        eventName: "sign",
        eventCode: "e0001",
        transactionNo: "",
    };

    useEffect(() => {
        if (response) {
            const postResponseData = async () => {
                try {
                    const postResponse = await axios.post(KakaoLoginAPI, response, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    const receivedProfileID = postResponse.data.message;
                    setProfileID(receivedProfileID);
                    console.log(`postResponse.data.message : ${receivedProfileID}`);
                    console.log('POST 성공:', receivedProfileID);
                } catch (error) {
                    console.error('POST 요청 중 오류 발생:', error);
                }
            };

            postResponseData();
        }

        const kakaoScript = document.createElement('script');
        kakaoScript.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        kakaoScript.async = true;
        
        kakaoScript.onload = () => {
            if (window.Kakao) {
                window.Kakao.init(kakaoJSkey);
                setKakaoInitialized(true);
            }
        };
        document.body.appendChild(kakaoScript);
    }, [response]);

    useEffect(() => {
        if (profileID) {
            localStorage.setItem('profileID', profileID);
            setTimeout(() => {
                setLoading(false);
                setEventTracked(true); // 이벤트 트래커를 트리거합니다.
                history.push('/mypage');
            }, 2000);
        }
    }, [profileID, history]);

    const handleLogout = () => {
        if (kakaoInitialized && window.Kakao.Auth.getAccessToken()) {
            window.Kakao.Auth.logout(() => {
                console.log('카카오 로그아웃 성공');
                history.push('/');
            });
        }
    };

    return (
        <div>
            {profileID && <EventTracker eventParams={loginEventParams} />} {/* 로그인 이벤트 트래커 */}
            {loading ? (
                <div style={{textAlign: 'center'}}>
                    <h1>로그인 중...</h1>
                    <img style={{ height: '450px' }} src={loadingDefault} alt="로딩 중..." />
                    <p>잠시만 기다려 주세요 :)</p>
                </div>
            ) : (
                <div>
                    <button onClick={handleLogout}>카카오 로그아웃</button>
                    <h1>로그인 성공!</h1>
                    {response && (
                        <div>
                            <h2>로그인 응답 데이터:</h2>
                            <pre>{JSON.stringify(response, null, 2)}</pre>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default LoginHandler;
