import React, { useContext, useEffect } from 'react';
import { rdswrite } from '../config';
import { getCurrentDateTime } from '../utils/getCurrentDateTime'; // 현재 시간 읽어오기
import { isMobile, isDesktop } from '../utils/detectDevice'; // 디바이스 타입 읽어오기
import axios from 'axios';

const EventTracker = ({ eventParams }) => {
  const { pageName, pageCode, eventName, eventCode, transactionNo } = eventParams;

  useEffect(() => {
    let cateUid = "";
    let uid = "";
    let deviceType = "";

    console.log("pageName:", pageName);
    console.log("pageCode:", pageCode);
    console.log("eventName:", eventName);
    console.log("eventCode:", eventCode);
    console.log("transactionNo:", transactionNo);

    //사용자ID
    const storedProfileID = localStorage.getItem('profileID');
    //로그인 여부 기반 uid, cateId
    if (storedProfileID==="") {
      cateUid = 'session';
        uid = 'session_test';
    } else {
        cateUid = 'profile';
        uid = storedProfileID;
    }

    // 방문시간
    const eventTime = getCurrentDateTime();

    // 디바이스 타입 
    if      (isMobile())  deviceType = 'mw';
    else if (isDesktop()) deviceType = 'web';
    
    // 방문값 누적 적재용 (PK)
    const uniEvnetCode = uid + "_" + eventCode + "_" + eventTime;

    // DB 저장용 배열 세팅
    const items = {
        "uni_event_code"   : uniEvnetCode,
        "page_name"        : pageName,
        "page_code"        : pageCode,
        "event_name"       : eventName,
        "event_code"       : eventCode,
        "uid"              : uid,
        "cate_uid"         : cateUid,
        "event_time"       : eventTime,
        "device_type"      : deviceType,
        "transation_no"    : transactionNo
    }
    
    console.log("uniEvnetCode:", items.uniEvnetCode);
    console.log("pageName:", items.pageName);
    console.log("pageCode:", items.pageCode);
    console.log("eventName:", items.eventName);
    console.log("eventCode:", items.eventCode);
    console.log("uid:", items.uid);
    console.log("cate_uid:", items.cateUid);
    console.log("eventTime:", items.eventTime);
    console.log("device_type:", items.deviceType);
    console.log("transation_no:", items.transactionNo);
    


    // saveDataToDynamoDB(tableEventInfo, items);
    // const saveData = async () => {
    //   try {
    //     const data = await saveDataToDynamoDB(tableEventInfo, items);
    //     console.log('Data saved:', data);
    //   } catch (error) {
    //     console.error('Error saving data:', error);
    //   }
    // };
    // saveData();

    // RDS에 저장할 컬럼명
    const columns = [
      "uni_event_code", "page_name", "page_code", "event_name", 
      "event_code", "uid", "cate_uid", "event_time", 
      "device_type", "transation_no"
    ];

    // Lambda에 데이터 전송 함수
    const saveDataToRDS = async () => {
      try {
        const response = await axios.post(rdswrite, {
          table_name: "tb_event_info", // 삽입할 테이블 이름
          columns: columns,         // 테이블 컬럼명
          values: [Object.values(items)] // 삽입할 데이터 배열
        });
        console.log('Data saved to RDS tb_event_info:', response.data);
      } catch (error) {
        console.error('Error saving data to RDS tb_event_info:', error);
      }
    };

    saveDataToRDS();


  }, [pageName, pageCode, eventName, eventCode, transactionNo]);

  return null;
};

export default EventTracker;