import React from 'react'

import { BrowserRouter, Route } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import ProductViewModal from './ProductViewModal'

import Routes from '../routes/Routes'
import './Layout.css'
import katalkBtn from '../assets/images/katalk_button.png'


const Layout = () => {

    return (
        <BrowserRouter>
            <Route render={props => (
                <div>
                    <Header {...props} />
                    <div className="container">
                        <div className="main">
                            <Routes />
                        </div>
                    </div>
                    <div className="katalkCSbtn">
                        <a href="http://pf.kakao.com/_SxltHG/chat" 
                            target="_blank" rel="noopener noreferrer">
                        <img src={katalkBtn}
                            alt="KatalkCSbtn"
                        />
                        <p>카톡문의</p>
                        </a>
                    </div>
                    <Footer />
                    <ProductViewModal />
                </div>
            )} />
        </BrowserRouter>
    )
}

export default Layout
