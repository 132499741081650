import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { useDispatch } from 'react-redux'

import { addItem } from '../redux/shopping-cart/cartItemsSlide'
import { remove } from '../redux/product-modal/productModalSlice'

import Button from './Button'
import numberWithCommas from '../utils/numberWithCommas'
import HeroSlider from './HeroSlider'
import heroSliderData from '../assets/fake-data/hero-slider'
import Helmet from './Helmet'
import Swal from 'sweetalert2';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import Papa from 'papaparse'; // 엑셀 파싱 모듈
import { saveAs } from 'file-saver'; // 파일 저장 모듈
import { getCurrentDateTime } from '../utils/getCurrentDateTime'; // 현재 시간 읽어오기
import loadingDefault from '../assets/images/loading_default.gif';
import {
    mainColor,
    tossClientKeClientLive, tossClientKeySecretLive,
    scraperYoutubeCommentLight, scraperYoutubeCommentMain,
    scraperGooglePlayStoreReviewLight, scraperGooglePlayStoreReviewMain,
    rdsread, PI_S3_GETOBJ1
} from '../config';
import { ScanCommand } from '@aws-sdk/lib-dynamodb';
import InfoTooltip from './InfoTooltip'; // 데이터 다운로드 받을 수 있는 곳 안내용
import ProgressBarScrap from '../components/ProgressBarScrap'; // 크롤링 진척 확인용

// const widgetSecretKey = TOSS_PAYMENT_LIVE_KEY;
// const encryptedSecretKey = "Basic " + btoa(widgetSecretKey + ":").toString("base64");

//---------------------------------------------------//
const ProductView = props => {
    const dispatch = useDispatch()
    let product = props.product;
    console.log(`Object.keys(props) : ${Object.keys(props)}`);
    console.log(`props.product : ${props.product}`);

    if (product === undefined) product = {
        title: "",
        price: '',
        image01: null,
        image02: null,
        categorySlug: "",
        colors: [],
        slug: "",
        size: [],
        description: "",
        descriptionTag: "",
        dataCrawlingType: [],
        urlInputGuideText: "",
        urlInputGuideTextSub: "",

    }

    // 수집 테스트 완료여부 트래킹 위한 변수 추가 | 2024-09-08 | 경빈
    const [loadingScrapLightFlag, setLoadingScrapLightFlag] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    const [previewImg, setPreviewImg] = useState(product.imageDetail)
    const [descriptionExpand, setDescriptionExpand] = useState(false)
    const [color, setColor] = useState(undefined)
    const [size, setSize] = useState(undefined)
    const [quantity, setQuantity] = useState(1)

    const [isClickedScrapMain, setIsClickedScrapMain] = useState(false);

    const history = useHistory();  // useHistory 훅 초기화

    //-------------------크롤러 타입 설정-------------------//
    const currentUrl = window.location.href;

    const [dataCollected, setDataCollected] = useState([]);

    console.log(`현재 URL : ${currentUrl}`);
    let serviceCode = '';
    let requestURLbase = '';
    let scrapLightAPI = '';
    let scrapMainAPI = '';
    let fileNotUploadMsg = '';
    let BUCKET_NAME = '';
    let orderName = '';

    if (currentUrl.includes('instagram') | currentUrl.includes('other')) {
        Swal.fire({
            icon: 'error',
            title: '🛠️서비스 준비 중',
            html: "8월 중 오픈 예정입니다 :)",
            confirmButtonText: '확인',
        });
        history.push('/');

    }
    else if (currentUrl.includes('youtube')) {
        serviceCode = 'cr_0002';
        requestURLbase = "https://www.youtube.com/";
        scrapLightAPI = scraperYoutubeCommentLight;
        scrapMainAPI = scraperYoutubeCommentMain;
        fileNotUploadMsg = '수집할 앱 URL을 보고 계신 유튜브에서<br/>그대로 복사+붙여넣기 해주세요. \
                            <br/><br/><예시><br/>https://www.youtube.com/watch?v=mSACOKgkc3U';
        BUCKET_NAME = "data101-youtube-data"
        orderName = "유튜브 댓글 수집";

    } else if (currentUrl.includes('google')) {
        serviceCode = 'cr_0001';
        requestURLbase = "https://play.google.com/store/apps/details?id";
        scrapLightAPI = scraperGooglePlayStoreReviewLight;
        scrapMainAPI = scraperGooglePlayStoreReviewMain;
        fileNotUploadMsg = '수집할 앱 URL을 구글 플레이 스토어에서<br/>그대로 복사+붙여넣기 해주세요. \
                            <br/><br/><예시><br/>https://play.google.com/store/apps/details?id=com.sampleapp&hl=ko'
        BUCKET_NAME = "data101-playstore-data"
        orderName = "구글 플레이 스토어 앱 리뷰 수집";

    } else {
        serviceCode = 'ERROR';
        requestURLbase = 'ERROR';
        scrapLightAPI = 'ERROR';
    }
    //-------------------------------------------------//



    const [profileID, setProfileID] = useState('');
    // 수집 요청 앱 / 유튜브 링크
    const [requestURL, setRequestURL] = useState('');
    // 수집 요청 건수

    // 거래번호
    const [transactionNo, setTransactionNo] = useState("");
    const [requestCNT, setRequestCNT] = useState(1000);
    const [productCode, setProductCode] = useState('');
    const checkboxTextData = [
        { id: 1, text: '리뷰 내용' },
        { id: 2, text: '리뷰 평점' },
        { id: 3, text: '리뷰 등록일' },
        { id: 4, text: '작성자명' },
        { id: 5, text: '좋아요 개수' },
    ];

    const optionPrices = {
        option1: 2900, // 항목 1의 가격
        option2: 5900, // 항목 2의 가격
        option3: 8900, // 항목 3의 가격
        option4: 11900, // 항목 4의 가격
        option5: 14900, // 항목 5의 가격
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [estimatedCost, setEstimatedCost] = useState(optionPrices.option1);
    const [scrapedDataList, setScrapedDataList] = useState([]);
    const [scrapedMainDataList, setScrapedMainDataList] = useState([]);


    const [tableHeight, setTableHeight] = useState(0);
    const tableRef = useRef(null);


    const updateQuantity = (type) => {
        if (type === 'plus') {
            setQuantity(quantity + 1)
        } else {
            setQuantity(quantity - 1 < 1 ? 1 : quantity - 1)
        }
    }

    useEffect(() => {
        setPreviewImg(product.imageDetail)
        setQuantity(1)
        setColor(undefined)
        setSize(undefined)
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        if (tableRef.current) {
            setTableHeight(tableRef.current.offsetHeight);
        }

        console.log(`isClickedScrapMain : ${isClickedScrapMain}`);
        if (loadingScrapLightFlag) {
            const timer = setTimeout(() => {
                setLoadingScrapLightFlag(false);
                setLoadingComplete(true);
            }, 3000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [product, scrapedDataList, productCode, transactionNo])


    const [selectedCheckboxes, setSelectedCheckboxes] = useState([1, 2, 3, 4, 5]); // 모든 체크박스를 선택 상태로 설정

    // input 요소의 값이 변경될 때 호출되는 함수
    const handleInputChange = (e) => {
        try {
            setRequestURL(e.target.value); // 입력된 값을 상태에 업데이트
            console.log(`수집 요청 URL: ${e.target.value}`);
            console.log(`requestURL : ${requestURL} / profileID : ${profileID}/ requestCNT : ${requestCNT}/ transactionNo : ${transactionNo}/
                service_code : ${serviceCode}/ product_code : ${productCode}`);

            const requestURLtemp = e.target.value;
            if (serviceCode == 'cr_0002') {
                const productCodeExtract = requestURLtemp.split("watch?v=");
                console.log(`productCodeExtract: ${productCodeExtract}`);
                setProductCode(productCodeExtract[1]);
            }

            else if (serviceCode == 'cr_0001') {
                const productCodeExtract = requestURLtemp.split("details?id=")[1];
                console.log(`productCodeExtract: ${productCodeExtract}`);

                let productCodeExtractPrep = productCodeExtract; // productCodeExtract 전체로 초기화

                const endIndex = productCodeExtractPrep.indexOf("&");
                console.log(`endIndex: ${endIndex}`);

                if (endIndex !== -1) {
                    productCodeExtractPrep = productCodeExtractPrep.substring(0, endIndex);
                }

                console.log(`productCodeExtractPrep: ${productCodeExtractPrep}`);
                setProductCode(productCodeExtractPrep);

            }

        } catch (error) {
            console.log(`URL 입력 중 에러 : ${error}`);
        }
    };

    const decodeUnicode = (str) => {
        try {
            return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });

        } catch (error) {
            console.log(`decodeUnicode Error : ${error}`);
            return;
        }

    };


    // 폼 제출 시 호출되는 함수
    const handleScrapLight = (e) => {
        try {
            setLoadingScrapLightFlag(true);
            console.log(`수집 테스트 클릭 시 profileID : ${profileID}`);
            // console.log(`수집 테스트 클릭 시 profileID : ${typeof (profileID)}`);

            if (!profileID) {
                Swal.fire({
                    icon: 'error',
                    title: '🎈로그인 필요!',
                    html: "크롤링 테스트는 무료이나 로그인이 필요해요 :)",
                    confirmButtonText: '로그인하러 가기',
                });
                history.push('/login');

            } else {
                setIsClickedScrapMain(false);
                e.preventDefault(); // 기본 제출 동작 방지
                // 입력된 텍스트를 처리하는 로직을 추가할 수 있습니다.
                console.log('사용자 입력 URL:', requestURL);



                //---------------수집 URL 정상 입력 여부 확인 로직---------------//
                if (requestURL.includes(requestURLbase)) {
                    console.log('앱 URL 포맷 확인 / 사용자 입력 URL:', requestURL);
                    const paymentItems = loadPaymentDB('tb_payment');
                    createTransactionNo(paymentItems, 20);
                    Swal.fire({
                        icon: 'success',
                        title: 'Data 수집 테스트를 시작했어요',
                        html: '아래에 데이터 미리보기를 확인해주세요',
                        confirmButtonText: '확인',
                    });

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'URL을 다시 확인해 주세요',
                        html: fileNotUploadMsg,
                        confirmButtonText: '확인',
                    });
                    setScrapedDataList([]);
                    return;
                }

                //---------------------------------------------------------//


                console.log(`serviceCode : ${serviceCode} / productCode : ${productCode}`);

                const req = {
                    "requestURL": requestURL,
                    "profileID": profileID,
                    "requestCNT": requestCNT,
                    "transactionNo": transactionNo,
                    "service_code": serviceCode,
                    "product_code": productCode
                };

                const response = axios.post(scrapLightAPI, req,
                    {
                        headers: {
                            'Content-Type': 'application/json', // JSON 데이터로 전송
                        },
                    })
                    .then((res) => {
                        console.log(`res : ${res.data}`);
                        console.log(`Object.keys(res.data) : ${Object.keys(res.data)}`);
                        console.log(`res.data.statusCode : ${res.data.statusCode}`);
                        console.log(`res.data.body : ${res.data.body}`);
                        const encodedText = res.data.body;
                        // console.log(`encodedText : ${encodedText}`);
                        const decodedText = decodeUnicode(encodedText);
                        console.log(`decodedText : ${decodedText}`);
                        console.log(`typeof(decodedText): ${typeof (decodedText)}`);
                        setScrapedDataList(JSON.parse(decodedText))
                        // console.log(`scrapedDataList: ${scrapedDataList_}`);
                        // console.log(`scrapedDataList[1].comment: ${scrapedDataList_[1].comment}`);

                })

            }


        } catch (error) {
            console.log(`${serviceCode} 크롤러 라이트 버전 에러 : ${error}`);
            setLoadingScrapLightFlag(false);

        }


    };

    const dataPreviewShow = () => {
        try {
            if (!loadingScrapLightFlag && scrapedDataList.length >= 1) {
                // 텍스트의 최대 길이를 반환하는 함수
                const getMaxTextWidth = (texts) => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    let maxWidth = 0;

                    texts.forEach((text) => {
                        const width = context.measureText(text).width;
                        if (width > maxWidth) {
                            maxWidth = width + 50;
                        }
                    });

                    return maxWidth;
                };


                if (serviceCode == 'cr_0002') {
                    // 등록일과 좋아요 수의 최대 텍스트 길이를 측정
                    const maxRegDtWidth = getMaxTextWidth(scrapedDataList.map(data => data.reg_dt.substring(0, 19)));
                    const maxUserNameWidth = getMaxTextWidth(scrapedDataList.map(data => data.user_id));
                    const maxLikeCntWidth = getMaxTextWidth(scrapedDataList.map(data => String(data.like_cnt)));

                    return (
                        <div style={{ overflowX: 'auto' }}>
                            <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                                <colgroup>
                                    <col style={{ width: maxRegDtWidth + 25 }} />
                                    <col style={{ width: maxUserNameWidth }} />
                                    <col style={{ width: 'auto' }} />
                                    <col style={{ width: maxLikeCntWidth + 20 }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>등록일</th>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>닉네임</th>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>댓글</th>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>좋아요 수</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scrapedDataList.map((data, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: '10px', whiteSpace: 'nowrap', textAlign: 'center', border: '0.5px solid black' }}>{data.reg_dt.substring(0, 19)}</td>
                                            <td style={{ padding: '10px', whiteSpace: 'nowrap', textAlign: 'center', border: '0.5px solid black' }}>{data.user_id.replace('@', '')}</td>
                                            <td style={{ padding: '10px', whiteSpace: 'normal', wordBreak: 'break-word', border: '0.5px solid black' }}>{data.comment}</td>
                                            <td style={{ padding: '10px', whiteSpace: 'nowrap', textAlign: 'center', border: '0.5px solid black' }}>{data.like_cnt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                } else if (serviceCode == 'cr_0001') {

                    // 등록일, 등록인, 평점의 최대 텍스트 길이를 측정
                    const maxRegDtWidth = getMaxTextWidth(scrapedDataList.map(data => data.date.substring(0, 19))) + 25;
                    const maxUserNameWidth = getMaxTextWidth(scrapedDataList.map(data => data.username));
                    const maxScoreWidth = getMaxTextWidth(scrapedDataList.map(data => String(data.score)));

                    return (
                        <div style={{ overflowX: 'auto' }}>
                            <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                                <colgroup>
                                    <col style={{ width: maxRegDtWidth }} />
                                    <col style={{ width: maxUserNameWidth + 25 }} />
                                    <col style={{ width: maxScoreWidth }} />
                                    <col style={{ width: 'auto' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>등록일</th>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>닉네임</th>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>평점</th>
                                        <th style={{ padding: '10px', border: '0.5px solid black' }}>리뷰내용</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scrapedDataList.map((data, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: '10px', whiteSpace: 'nowrap', textAlign: 'center', border: '0.5px solid black' }}>{data.date.substring(0, 19)}</td>
                                            <td style={{ padding: '10px', whiteSpace: 'nowrap', textAlign: 'center', border: '0.5px solid black' }}>{data.username}</td>
                                            <td style={{ padding: '10px', whiteSpace: 'nowrap', textAlign: 'center', border: '0.5px solid black' }}>{data.score}</td>
                                            <td style={{ padding: '10px', whiteSpace: 'normal', wordBreak: 'break-word', border: '0.5px solid black' }}>{data.content}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                }

            }

            else if (loadingScrapLightFlag) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '400px',
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',
                            fontSize: '1.5em',
                            borderRadius: '10px', // 둥근 모서리 추가
                            padding: '3px' // 내부 여백 추가
                        }}
                    >

                        <img style={{ height: '150px' }} src={loadingDefault} alt="로딩 중..." />
                        <p>테스트 수집을 시작했어요, 최대 15초까지 걸립니다 :)</p>
                    </div>
                )
            }


            else {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '200px',
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',
                            fontSize: '1.5em',
                            borderRadius: '10px', // 둥근 모서리 추가
                            padding: '3px' // 내부 여백 추가
                        }}
                    >
                        수집 완료 시 일부 Data가 미리 보여집니다.
                    </div>
                );
            }

        } catch (error) {
            console.log(`preview show error : ${error}`);
            return;

        }

    };



    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(e.target.value);
        console.log(`selectedValue: ${selectedValue}`);
        if (selectedValue === 'option1') setRequestCNT(1000);
        else if (selectedValue === 'option2') setRequestCNT(2000);
        else if (selectedValue === 'option3') setRequestCNT(3000);
        else if (selectedValue === 'option4') setRequestCNT(4000);
        else if (selectedValue === 'option5') setRequestCNT(5000);


        // 선택된 항목에 해당하는 가격으로 견적 계산
        if (selectedValue && optionPrices[selectedValue] !== undefined) {
            const cost = optionPrices[selectedValue];
            setEstimatedCost(cost);
        } else {
            setEstimatedCost(0); // 선택된 항목이 없거나 가격이 정의되지 않은 경우
        }

    };

    const handleBtnScraperMain = () => {
        setIsClickedScrapMain(true);

        console.log(`버튼 클릭 여부 : ${isClickedScrapMain}`);
    };

    // 결제 및 수집 시작 버튼 클릭 시
    const handleScrapMain = () => {
        console.log(`결제 및 수집 시작 버튼 클릭!`);
// <<<<<<< HEAD
//         Swal.fire({
//             icon: 'success',
//             title: 'Data 수집을 시작했어요!',
//             html: '오른쪽 위 ⤴︎ 마이페이지에서 수집 결과를 확인해 보세요',
//             confirmButtonText: '확인',
//         });
//         handleBtnScraperMain(); // 수집시작 클릭 시 중복 클릭 방지
// =======
        handlePopupOpen();
        // Swal.fire({
        //     icon: 'success',
        //     title: 'Data 수집을 시작했어요!',
        //     html: '우측 상단 마이페이지에서 수집 결과를 확인해 보세요',
        //     confirmButtonText: '확인',
        // });
        // handleBtnScraperMain(); // 수집시작 클릭 시 중복 클릭 방지



        if (scrapedDataList.length > 0) {
            try {
                console.log(`수집 테스트 정상 완료 건!`);
                const req = {
                    "requestURL": requestURL,
                    "profileID": profileID,
                    "requestCNT": requestCNT,
                    "transactionNo": transactionNo,
                    "service_code": serviceCode,
                    "product_code": productCode
                };

                console.log(`------req 확인------`);
                console.log(`1. requestURL : ${requestURL}`);
                console.log(`2. profileID : ${profileID}`);
                console.log(`3. requestCNT : ${requestCNT}`);
                console.log(`4. transactionNo : ${transactionNo}`);
                console.log(`5. service_code : ${serviceCode}`);
                console.log(`6. product_code : ${productCode}`);
                console.log(`-------------------`);


                // admin 계정에서는 결제창 팝업 미실행
                const currentURL = window.location.href;
                // if (!currentURL.includes('admin')) handlePopupOpen();

                const response = axios.post(scrapMainAPI, req,
                    {
                        headers: {
                            'Content-Type': 'application/json', // JSON 데이터로 전송
                        },
                    })
                    .then((res) => {
                        console.log(`main 크롤링 요청 완료!`);
                        console.log(`res : ${res.data}`);
                    })

            } catch (error) {
                console.log(`메인 크롤링 에러 발생 : ${error}`);
                Swal.fire({
                    icon: 'error',
                    title: 'Data 수집 실패',
                    text: '데이터 수집 중 오류가 발생했습니다. 다시 시도해 주세요.',
                    confirmButtonText: '확인',
                });
                setDataCollected([]);
            }
        }
        // 데이터 수집 테스트 전
        else {
            Swal.fire({
                icon: 'error',
                title: 'Data 수집 테스트 필요',
                text: '먼저 URL 입력하고 테스트를 진행해 주세요',
                confirmButtonText: '확인',
            });
            setDataCollected([]);
            return;
        }
    }
    
    // 거래번호 난수생성 함수
    const createTransactionNo = (transactionNo_obj, length) => {
        if (typeof transactionNo_obj !== 'object' || transactionNo_obj === null) {
            throw new Error('transactionNo_obj must be an object');
        }

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        while (true) {
            let transactionNo_temp = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                transactionNo_temp += characters.charAt(randomIndex);
            }

            const transactionNos = Object.values(transactionNo_obj).map(item => item.transaction_no);
            const exists = transactionNos.some(transactionNo => transactionNo === transactionNo_temp);

            if (!exists) {
                console.log(`transactionNo_temp 유니크: ${transactionNo_temp}`);
                setTransactionNo(transactionNo_temp);
                return;
            } else {
                console.log(`동일 거래번호 존재: ${transactionNo_temp}`);
            }
        }
    };

    // 쿼리해서 해당 사용자만 가져오기.
    const loadPaymentDB = async (tableName) => {
        try {
    
        //RDS
        const response = await axios.post(rdsread, {
            table_name: tableName,
        });

        // 응답에서 데이터 추출
        const responseData = response.data.body;
        let data;
        if (typeof responseData === 'string') {
            data = JSON.parse(responseData);
        } else {
            data = responseData;
        }
        console.log(`data : ${data}`);
        console.log(`tableName : ${tableName}`);
        
    } catch (error) {
        console.log(`error: ${error}`);
    } };



    const handlePopupOpen = () => {
        // SweetAlert2로 팝업 생성
        Swal.fire({
            title: '📌 데이터 수집을 위한 마지막 절차예요!',
            html: `
        
       <div><p style="margin-top: 10px; font-size: 20px; ">아래 사항을 꼼꼼이 읽고 동의해 주세요.</p></div><br/><br/>
        <div style="display: grid; grid-template-columns: 2fr; gap: 10px;">
          <div style="display: flex; align-items: center;">
             <input type="checkbox" id="checkbox1" style="width: 30px; height: 30px; margin-right: 15px;">
            <label for="checkbox1" style="font-size: 18px; display: inline-block; text-align: left;">
            * (필수) Data는 리뷰 작성일자 최신순으로 수집되며, 
            ${orderName} 정책상 앱/영상 정보에 표시된 전체 리뷰/댓글 개수보다 적게 수집될 수 있습니다. 
            또한, 수집 요청 건수 대비 실제 수집 건수가 적더라도 교환 및 환불이 불가합니다. 
            단, 수집 요청 건수보다 실제 수집한 건수가 50% 미만일 경우에는 100% 환불해 드립니다.
            환불 정책에 동의합니다.
            </label>
          </div>
          <div style="display: flex; align-items: center;">
            <input type="checkbox" id="checkbox2" style="width: 30px; height: 30px; margin-right: 15px;">
            <label for="checkbox2" style="font-size: 18px; display: inline-block; text-align: left;">
            * (필수) 요청하신 Data는 요청 시점으로부터 15분 이내 완료되며 마이 페이지에서 데이터 다운로드 및 수집 진행상황을 확인하실 수 있습니다. 
            Data 제공 일정 및 방법을 이해하였으며 절차에 동의합니다.
            </label>
          </div>
        </div>
        <br/>
        <div><p style="margin-top: 10px; font-size: 20px; ">결제금액 : <strong>${numberWithCommas(estimatedCost)}원</strong></p></div>
      `,
            confirmButtonText: '전체 동의 및 결제하기',
            confirmButtonColor: mainColor,
            showCloseButton: true,
            focusConfirm: false,
            preConfirm: () => {
                // 체크박스 상태 확인
                const checkbox1 = document.getElementById('checkbox1');
                const checkbox2 = document.getElementById('checkbox2');

                // 두 개의 체크박스가 모두 체크되었는지 확인
                if (!checkbox1.checked || !checkbox2.checked) {
                    // 경고 메시지 표시
                    Swal.showValidationMessage('모든 사항에 동의해야 결제를 진행할 수 있습니다.');
                    return false;
                }

                // 체크박스 모두 체크되었을 때 결제 진행 로직
                paymentPopUp();

                // console.log('선택된 옵션:', selectedOptions);
            },
            customClass: {
                container: 'custom-swal-container', // 커스텀 클래스 추가
            },
            width: '60%', // 팝업 너비 조절
            heightAuto: false, // 자동 높이 조절 비활성화

        });

    };
    
    const paymentPopUp = async () => {
            console.log("===========paymentPopUp=============");
            console.log(`amount : ${estimatedCost}, orderId: ${transactionNo}, orderName: ${orderName}, customerName: ${profileID}`);
            // ------ 클라이언트 키로 객체 초기화 ------
            try{
                const paymentDate = getCurrentDateTime();
                const paymentData = `orderId=${transactionNo}&amount=${estimatedCost}&profileID=${profileID}&serviceCode=${serviceCode}&productName=${"수집한서비스명"}&productCode=${productCode}&paymentDate=${paymentDate}&requestCNT=${requestCNT}`
                const tossPayments = await loadTossPayments(tossClientKeClientLive);
                tossPayments.requestPayment('카드', {
                    amount: estimatedCost, // 결제 금액
                    orderId: transactionNo, // 주문번호
                    orderName: orderName, // 구매상품
                    customerName: profileID, // 구매자 이름
                    successUrl: window.location.origin + `/paysuccess?${paymentData}`,
                    failUrl: `http://localhost:3000/payfail`, // 결제 실패 시 이동할 페이지(이 주소는 예시입니다. 상점에서 직접 만들어주세요.)
                }).then((result) => {
                    console.log('Payment successful:', result);
                    // 결제 성공 처리
                }).catch((error) => {
                    console.error('Payment failed:', error);
                    // 결제 실패 처리
                });
    
            } catch (error) {
                console.log(`Error : ${error}`);
            }
    };

    const showOptions = () => {
        if (serviceCode === 'cr_0002') {
            return (
                <>
                    {/* <option value="">건수 선택</option> */}
                    <option value="option1">1,000건</option>
                    <option value="option2">2,000건</option>
                    <option value="option3">3,000건</option>
                    <option value="option4">4,000건</option>
                    <option value="option5">5,000건</option>
                </>
            )
        } else if (serviceCode === 'cr_0001') {
            return (
                <>
                    {/* <option value="">건수 선택</option> */}
                    <option value="option1">1,000건</option>
                    <option value="option2">2,000건</option>
                    <option value="option3">3,000건</option>
                </>
            )
        }

    }


    return (
        <div>
            <h1>{product.title}</h1><br />
            <div>
                <img src={previewImg} alt="" style={{ height: '300px', border: '0.5px' }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <form onSubmit={handleScrapLight}>
                    <label htmlFor="textInput" style={{ fontSize: '20px' }}>{product.urlInputGuideText}</label>
                    <input
                        type="text"
                        id="requestURL"
                        value={requestURL}
                        onChange={handleInputChange}
                        style={{ width: '400px', height: '30px', fontSize: '16px', marginLeft: '10px', marginRight: '10px' }}
                    />
                    <Button
                        size="sm"
                        type="submit"
                        backgroundColor={mainColor}
                        style={{
                            height: '30px',
                            backgroundColor: mainColor,
                            color: '#fff',
                            padding: '8px 16px',
                            border: 'none',
                            borderRadius: '3px',
                            cursor: 'pointer',
                        }}
                    >
                        Data 수집 테스트
                    </Button>
                </form>
                <p style={{ fontSize: '16px', color: 'grey' }}>{product.urlInputGuideTextSub}</p>
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <p style={{ fontSize: '20px' }}>* 수집 Data 선택 : </p>
                {product.dataCrawlingType.map((item) => (
                    <div key={item.id} style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
                        <input
                            type="checkbox"
                            id={`checkbox-${item.id}`}
                            checked={selectedCheckboxes.includes(item.id)}
                            disabled
                        />
                        <label htmlFor={`checkbox-${item.id}`} style={{ marginLeft: '5px', fontSize: '18px' }}>{item.text}</label>
                    </div>
                ))}
            </div>
            <br /><br />
            <h2>🔍 Data 수집 테스트 결과</h2>
            <br />
            <div
                ref={tableRef}
                style={{
                    width: '100%',
                    maxHeight: '600px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxSizing: 'border-box',
                }}
            >
                <h2 style={{ textAlign: 'center' }}>데이터 미리보기</h2>
                <br />
                {dataPreviewShow()}
            </div>
            <br /><br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <label htmlFor="options" style={{ fontSize: '20px' }}>* Data 수집 요청 건수 :</label>
                <select
                    id="options"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    style={{ marginLeft: '10px', fontSize: '20px', width: '200px' }}
                >
                    {showOptions()}
                </select>
                <br/>
                <p style={{ fontSize: '20px'}}>#결제 금액 : {estimatedCost.toLocaleString()}원</p>
                <br/>
                <Button
                    size="sm"
                    onClick={handleScrapMain}
                    backgroundColor={isClickedScrapMain ? 'grey' : mainColor}
                    // disabled={isClickedScrapMain}
                    style={{
                        height: '30px',
                        color: '#fff',
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '3px',
                        cursor: isClickedScrapMain ? 'not-allowed' : 'pointer',

                    }}

                >
                    결제 및 데이터 수집 시작
                </Button>
                <InfoTooltip text="데이터는 오른쪽 위 ⤴︎ My Page에서 확인하실 수 있어요" />
            </div>
            <br /><br />
            <p style={{ fontSize: '16px', color: 'grey' }}>※ 더 많은 Data 수집 필요하신 경우 카톡 플러스친구로 문의해 주세요.</p>

            <br />
        </div>
    );
};


ProductView.propTypes = {
    product: PropTypes.object
}

export default withRouter(ProductView)
