import React from 'react'

import { Link } from 'react-router-dom'

import Grid from './Grid'

import logo from '../assets/images/logoData101.png'

const containerStyle = {
    display: 'flex',
    justifyContent: 'left', // 수평 가운데 정렬
    alignItems: 'center', // 수직 가운데 정렬
    height: '20vh', // 화면 전체 높이에 대해 가운데 정렬
};

// const imageStyle = {
//     // width: '100px',
//     // height: '100px',
//     marginRight: '20px', // 이미지와 텍스트 사이 간격 조정
// };


const textStyle = {
    fontSize: '17px',
    // fontWeight: 'bold',
    textAlign: 'left',
  };


// const footerAboutLinks = [
//     {
//         display: "Giới thiệu",
//         path: "/about"
//     },
//     {
//         display: "Liên hệ",
//         path: "/about"
//     },
//     {
//         display: "Tuyển dụng",
//         path: "/about"
//     },
//     {
//         display: "Tin tức",
//         path: "/about"
//     },
//     {
//         display: "Hệ thống cửa hàng",
//         path: "/about"
//     }
// ]

// const footerCustomerLinks = [
//     {
//         display: "Chính sách đổi trả",
//         path: "/about"
//     },
//     {
//         display: "Chính sách bảo hành",
//         path: "/about"
//     },
//     {
//         display: "Chính sách hoàn tiền",
//         path: "/about"
//     }
// ]
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container" style={containerStyle}>
                {/*
                <Grid
                    col={2}
                    mdCol={2}
                    smCol={1}
                    gap={10}
                >
                     <div>
                        <div className="footer__title">
                            Tổng đài hỗ trợ
                        </div>
                        <div className="footer__content">
                            <p>
                                Liên hệ đặt hàng <strong>0123456789</strong>
                            </p>
                            <p>
                                Thắc mắc đơn hàng <strong>0123456789</strong>
                            </p>
                            <p>
                                Góp ý, khiếu nại <strong>0123456789</strong>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="footer__title">
                            Về Yolo
                        </div>
                        <div className="footer__content">
                            {
                                footerAboutLinks.map((item, index) => (
                                    <p key={index}>
                                        <Link to={item.path}>
                                            {item.display}
                                        </Link>
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <div className="footer__title">
                            Chăm sóc khách hàng
                        </div>
                        <div className="footer__content">
                            {
                                footerCustomerLinks.map((item, index) => (
                                    <p key={index}>
                                        <Link to={item.path}>
                                            {item.display}
                                        </Link>
                                    </p>
                                ))
                            }
                        </div>
                    </div> */}
                {/* <div>
                    <p>
                        <Link to="/">
                            <img src={logo} className="footer__logo" alt="" style={imageStyle} />
                        </Link>
                    </p>
                </div> */}
                <div className="footer__about" style={textStyle}>
                    <p>
                        상호명 : 주식회사 유에프5 | 대표이사 : 박경빈 <br />
                        주소 : 서울특별시 강남구 남부순환로 359길 14, 3층<br />
                        사업자등록번호: 376-87-03088 | 통신판매신고번호 : 제2024-서울강남-03158호<br />
                        이메일 : gbpark@uf5.io | 전화번호 : 0507-1334-3546 <br />
                        © 2024 UF5, All Rights Reserved. | v0.3.3<br/>
                    </p>
                </div>
                {/* </Grid> */}
            </div>
        </footer>
    )
}

export default Footer
