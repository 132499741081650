import React, { useRef, useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import logo from '../assets/images/logoData101.png'
import KakaoLogin from 'react-kakao-login';

const logoStyle = {
    // width: '100px',
    position: 'fixed',
    height: '35px',
    top: '28px',
    left: '50%',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center', // 수평 가운데 정렬
    alignItems: 'center', // 수직 가운데 정렬
};

const mainNav = [
    {
        display: "",
        path: "/"
    },
    {
        display: "",
        path: "/"
    },   
    {
        display: "",
        path: "/"
    },   
    {
        display: "💒홈",
        path: "/"
    },    
    {
        display: "",
        path: "/"
    },    
    {
        display: "🔍데이터수집",
        path: "/catalog/google-app-review-scraper"
    },
    {
        display: "    ▶ App리뷰수집",
        path: "/catalog/google-app-review-scraper"
    },
    {
        display: "    ▶ Youtube댓글수집",
        path: "/catalog/youtube-comment-scraper"
    },
    {
        display: "",
        path: "/"
    },
    {
        display: "⏲데이터시각화",
        path: "/catalog/vis-wordcloud"
    },
    {
        display: "    ▶ 워드클라우드",
        path: "/catalog/vis-wordcloud"
    },
    {
        display: "    ▶ 키워드분석차트",
        path: "/catalog/vis-word-occurrence-frequency"
    },
    {
        display: "    ▶ 키워드네트워크",
        path: "/catalog/vis-word-cooccurrence-network"
    },    
]

// const MenuBar = () => (
//     <nav className="menu-bar">
//         <ul className="menu-bar__list">
//             <li className="menu-bar__item">
//                 <span>🔍데이터수집</span>
//                 <ul className="menu-bar__sublist">
//                     <li className="menu-bar__subitem">
//                         <Link to="/catalog/google-app-review-scraper">App리뷰수집</Link>
//                     </li>
//                     <li className="menu-bar__subitem">
//                         <Link to="/catalog/youtube-comment-scraper">Youtube댓글수집</Link>
//                     </li>
//                 </ul>
//             </li>
//             <li className="menu-bar__item">
//                 <span>⏲데이터시각화</span>
//                 <ul className="menu-bar__sublist">
//                     <li className="menu-bar__subitem">
//                         <Link to="/catalog/vis-wordcloud">워드클라우드</Link>
//                     </li>
//                     <li className="menu-bar__subitem">
//                         <Link to="/catalog/vis-word-occurrence-frequency">키워드분석차트</Link>
//                     </li>
//                     <li className="menu-bar__subitem">
//                         <Link to="/catalog/vis-word-cooccurrence-network">키워드네트워크</Link>
//                     </li>
//                 </ul>
//             </li>
//             <li className="menu-bar__item">
//                 <span>🐦AI분석</span>
//                 <ul className="menu-bar__sublist">
//                     <li className="menu-bar__subitem">
//                         <Link to="/">감성분류</Link>
//                     </li>
//                     <li className="menu-bar__subitem">
//                         <Link to="/">의도분류</Link>
//                     </li>
//                 </ul>
//             </li>
//         </ul>
//     </nav>
// );


const Header = () => {

    const { pathname } = useLocation()
    const history = useHistory();  // useHistory 훅 초기화
    const headerRef = useRef(null)
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    // 카카오 로그인 시 사용자 unique 값
    const [profileID, setProfileID] = useState('');

    const [loginBtnIsClicked, setLoginBtnIsClicked] = useState(false);
    const { Kakao } = window;

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');

        window.addEventListener("scroll", () => {
            if (headerRef.current) {
                if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                    headerRef.current.classList.add('shrink')
                } else {
                    headerRef.current.classList.remove('shrink')
                }
            }
        })
        return () => {
            window.removeEventListener("scroll")
        };
    }, []);

    const menuLeft = useRef(null)

    const menuToggle = () => menuLeft.current.classList.toggle('active')

    const handleLogoClick = () => {
        history.push('/');
    };

    const handleMyPageClick = () => {
        // 로그인 이력 있는 경우 마이페이지 이동
        if (profileID){
            history.push('/mypage');

        // 로그인 이력 없는 경우 로그인 페이지 이동
        } else {
            history.push('/login');
        }
    };

    return (
        <div className="header" ref={headerRef}>
            <div className="container">
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
                {/* <div className="header__logo"> */}
                {/* <div>
                    <img src={logo} style={logoStyle} alt="" onClick={handleLogoClick} />
                </div> */}

                <div className="header__menu" onClick={handleLogoClick}>
                {/* 이벤트 독립 실행 위해 stopPropagation 사용 */}
                    <div className="header__menu__mobile-toggle" onClick={(e) => { e.stopPropagation(); menuToggle(); }}> 
                        <i className='bx bx-menu-alt-left'></i>
                    </div>
                    <div className="header__menu__left" ref={menuLeft}>
                        <div className="header__menu__left__close" onClick={(e) => { e.stopPropagation(); menuToggle(); }}>
                            <i className='bx bx-chevron-left'></i>
                        </div>
                        {
                            mainNav.map((item, index) => (
                                <div
                                    key={index}
                                    className="header__menu__item header__menu__left__item"
                                >
                                    <Link
                                        to={item.path}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            menuToggle();
                                        }}
                                    >
                                        <span>{item.display}</span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                    <div className="header__menu__right">
                        {/* 불필요한 아이콘 제거 | 2024-05-28 */}
                        {/* <div className="header__menu__item header__menu__right__item">
                            <i className="bx bx-search"></i>
                        </div>
                        <div className="header__menu__item header__menu__right__item">
                            {/* <Link to="/cart"> */}
                        {/* <i className="bx bx-shopping-bag"></i> */}
                        {/* </Link> 
                        </div> */}

                        {/* 로그인 여부에 따라 로그인/로그아웃 문구 출력 */}
                        {/* <div className="header__menu__item header__menu__right__item">
                            {loginInfo()}
                        </div> */}
                        <div className="header__menu__item header__menu__right__item">

                            {/* 로그인 기능 추가 | 2024-05-19 */}
                            {/* <Link to="/login"> */}
                            <i className="bx bx-user" onClick={(e) => { e.stopPropagation(); handleMyPageClick(); }}></i>
                            {/* </Link> */}
                        </div>

                    </div>
                </div>
                {<div className="header__logo">
                        <img src={logo} style={logoStyle} alt="" />
                </div> }
                {/* <MenuBar /> */}
                <br/>
            </div>
        </div>
    )
}

export default Header






