import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import Helmet from '../components/Helmet'
import HeroSlider from '../components/HeroSlider'
import Section, { SectionTitle, SectionBody } from '../components/Section'
import PolicyCard from '../components/PolicyCard'
import Grid from '../components/Grid'
import ProductCard from '../components/ProductCard'

import heroSliderData from '../assets/fake-data/hero-slider'
import policy from '../assets/fake-data/policy'
import productData from '../assets/fake-data/products'
import productDataAnalysis from '../assets/fake-data/productsAnalysis'
import productDataAI from '../assets/fake-data/productsAI'

import banner from '../assets/images/banner.png'
import logo from '../assets/images/logoData101.png'
import { store } from '../redux/store';
import { isMobile, isDesktop } from '../utils/detectDevice'; // 디바이스 타입 읽어오기
// import { saveDataToDynamoDB, tableVisitInfo, tableEventInfo } from '../config';

const Home = () => {
    // 어드민 전용 로그인 여부 검증용
    const [isAdminLogin, setIsAdminLogin] = useState('');
    const inputRef = useRef(null);
    const [profileID, setProfileID] = useState('');

    //================퍼널 분석용 변수 세팅================//
    const [visitTime, setVisitTime] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [uuid, setUuid] = useState('');
    const [cateId, setCateId] = useState('');
    const pageName = "home";
    //================================================//
    
    // const [inputValue, setInputValue] = useState('');
    // const [isMatch, setIsMatch] = useState(false);


    // const handleChange = (event) => {
    //     setInputValue(event.target.value);
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault(); // 폼 제출 시 페이지 새로고침 방지
    //     let inputValue = inputRef.current.value;
    //     console.log(`inputValue: ${inputValue}`);
    //     if (inputValue == adminPW) {
    //     setIsMatch(true);
    //     console.log("값 일치");
    //     setInputValue("");
        
    //     } else {
    //     setIsMatch(false);
    //     setInputValue("");
    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'PW 불일치',
    //         text: '관계자 외 접근 불가 페이지',
    //         confirmButtonText: '확인',
    //     });
    //     }
    // };

    console.log(`productData: ${productData}`);
    console.log(`Object.keys(productData): ${Object.keys(productData)}`);
    // 

    useEffect(() => {
        window.scrollTo(0, 0);

        //======================사용자ID저장==========================
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        //==========================================================

        const storedIsAdminLogin = localStorage.getItem('isLoginAdmin');
        setIsAdminLogin(storedIsAdminLogin || '');

    }, []);
    console.log(`profileID: ${profileID}`);
    console.log(`isAdminLogin: ${isAdminLogin}`);

    const MainLandingPage = () => {

    return (
        <Helmet title="Trang chủ">
            <div
                style={{
                    display: 'flex',          // 부모 요소를 Flexbox로 설정
                    justifyContent: 'center', // 가로축에서 중앙 정렬
                    alignItems: 'center',     // 세로축에서 중앙 정렬 (필요 시 사용)
                    height: '100%',           // 필요 시 전체 높이를 사용
                }}
            >
                <video
                    style={{ 
                        width: '100%', 
                        height: 'auto', 
                        display: 'block', 
                        borderRadius: '15px',
                    }}
                    playsInline
                    loop
                    autoPlay
                    muted
                >
                    <source src="/videos/mainvideo.mp4" type="video/mp4" />
                </video>
            </div>
            <br/><br/>

            {/* hero slider */}



            {/* <HeroSlider
                data={heroSliderData}
                // control={true}
                auto={false}
                timeOut={5000}
            /> */}



            {/* end hero slider */}

            {/* policy section */}
            {/* <Section>
                {/* policy section */}
                {/* <Section>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={1}
                        gap={20}
                    >
                        {
                            policy.map((item, index) => <Link key={index} to="/policy">
                                <PolicyCard
                                    name={item.name}
                                    description={item.description}
                                    icon={item.icon}
                                />
                            </Link>)
                        }
                    </Grid>
                </SectionBody>
            </Section> */}
                {/* end policy section */}

                {/* best selling section */}
                <Section>
                    <SectionTitle>
                        🔍 편리한 데이터 분석 시작하기
                    </SectionTitle>
                    <SectionBody>
                        <Grid
                            col={4}
                            mdCol={2}
                            smCol={1}
                            gap={20}
                        >
                            {
                                productData.getProducts(3).map((item, index) => (
                                    <ProductCard
                                        key={index}
                                        img01={item.image01}
                                        img02={item.image02}
                                        name={item.title}
                                        price={Number(item.price)}
                                        description={item.description}
                                        descriptionTag={item.descriptionTag}
                                        slug={item.slug}
                                        categorySlug={item.categorySlug}
                                    />
                                ))
                            }
                        </Grid>
                    </SectionBody>
                </Section>
                {/* end best selling section */}

                {/* new arrival section */}




                {/* <Section>
                    <SectionTitle>
                        ⏲ 3분 만에 다양한 데이터 분석 해보기
                    </SectionTitle>
                    <SectionBody>
                        <Grid
                            col={4}
                            mdCol={2}
                            smCol={1}
                            gap={20}
                        >
                            {
                                productDataAnalysis.getProducts(4).map((item, index) => (
                                    <ProductCard
                                        key={index}
                                        img01={item.image01}
                                        img02={item.image02}
                                        name={item.title}
                                        price={Number(item.price)}
                                        description={item.description}
                                        descriptionTag={item.descriptionTag}
                                        slug={item.slug}

                                    />
                                ))
                            }
                        </Grid>
                    </SectionBody>
                </Section>

                <Section>
                    <SectionTitle>
                        🐦 최신 AI 알고리즘 간편하게 사용해보기
                    </SectionTitle>
                    <SectionBody>
                        <Grid
                            col={4}
                            mdCol={2}
                            smCol={1}
                            gap={20}
                        >
                            {
                                productDataAI.getProducts(4).map((item, index) => (
                                    <ProductCard
                                        key={index}
                                        img01={item.image01}
                                        img02={item.image02}
                                        name={item.title}
                                        price={Number(item.price)}
                                        description={item.description}
                                        descriptionTag={item.descriptionTag}
                                        slug={item.slug}

                                    />
                                ))
                            }
                        </Grid>
                    </SectionBody>
                </Section> */}




                {/* end new arrival section */}

                {/* banner */}
                {/* <Section>
                <SectionBody>
                    <Link to="/catalog">
                        <img src={banner} alt="" />
                    </Link>
                </SectionBody>
            </Section> */}
                {/* end banner */}

                {/* popular product section */}
                {/* <Section>
                <SectionTitle>
                    phổ biến
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={1}
                        gap={20}
                    >
                        {
                            productData.getProducts(12).map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    slug={item.slug}
                                />
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section> */}
                {/* end popular product section */}
            </Helmet>
        )
    }

    // const InputForm = () => {
    //     return (
            
    //         <div style={{alignContent: 'center', 
    //             textAlign : 'center'
    //         }}>
    //             <form onSubmit={handleSubmit} 
    //             >관리자 번호 입력
    //         <input
    //       type="text"
    //       ref={inputRef}
    //       placeholder="Enter the secret text"
    //     />
    //         <button type="submit">Submit</button>
    //     </form>
    //         </div>
    //     );
    // };


    return (
        <div>
            {/* {(isMatch ? <MainLandingPage /> : <InputForm />)} */}
            <MainLandingPage />
        </div>
    );
}

export default Home
