const product_01_image_01 = require('../images/products/thumnails_unified/scraper_1_google-paly-store_unified.png')
const product_01_image_02 = require('../images/products/scraper_google_app_detail.png')
const product_01_image_detail = require('../images/products/scraper_googleapp_detail1.png')

const product_02_image_01 = require('../images/products/thumnails_unified/scraper_2_yotube_unified.png')
const product_02_image_02 = require('../images/products/scraper_youtube_detail.png')
const product_02_image_detail = require('../images/products/scraper_youtube_detail1.png')

const product_03_image_01 = require('../images/products/thumnails_unified/vis_3_network_unified.png')
const product_03_image_02 = require('../images/products/analysis_network_detail.png')
const product_03_image_detail = require('../images/products/analysis_tot_detail2.png')

const product_04_image_01 = require('../images/products/thumnails_unified/vis_4_model.png')
const product_04_image_02 = require('../images/products/deeplearning.PNG')
const product_04_image_detail = require('../images/products/analysis_tot_detail2.png')


const products = [
    {
        title: "📱 App 리뷰 수집",
        urlInputGuideText : "* 수집할 앱 URL 입력 :",
        urlInputGuideTextSub : "※ 구글 플레이 스토어 App 상세 페이지 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '리뷰 내용' },
            { id: 2, text: '리뷰 평점' },
            { id: 3, text: '리뷰 등록일' },
            { id: 4, text: '작성자명' },
            { id: 5, text: '좋아요 개수' },
        ],
        price: '189000',
        image01: product_01_image_01,
        image02: product_01_image_02,
        imageDetail: product_01_image_detail,
        categorySlug: "scraper",
        colors: ["white", "red", "orange"],
        slug: "google-app-review-scraper",
        size: ["s", "m", "l", "xl"],
        description: "생생한 리뷰를 클릭 몇번만에!",
        descriptionTag : "#구글플레이스토어 #리뷰"
    },
    {
        title: "🎬 Youtube 댓글 수집",
        urlInputGuideText : "* 수집할 영상 URL 입력 :",
        urlInputGuideTextSub : "※ 수집할 영상 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '159000',
        image01: product_02_image_01,
        image02: product_02_image_02,
        imageDetail: product_02_image_detail,
        categorySlug: "scraper",
        colors: ["white", "red", "blue"],
        slug: "youtube-comment-scraper",
        size: ["s", "m"],
        description: "내 영상의 반응을 전부 수집!",
        descriptionTag : "#유튜브 #댓글 #대댓글"
    },
    {
        title: "🎈 텍스트 데이터 시각화",
        urlInputGuideText : "* 수집할 게시물 URL 입력 :",
        urlInputGuideTextSub : "※ 수집할 게시물 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '190000',
        image01: product_03_image_01,
        image02: product_03_image_02,
        imageDetail: product_03_image_detail,
        categorySlug: "visualize",
        colors: ["white", "red", "orange", "yellow"],
        slug: "visualization",
        size: ["m"],
        description: "세가지 시각화를 한번에!",
        descriptionTag : "#트렌드 #키워드 #연관성"
    },
    {
        title: "😀 AI 감정 분류",
        urlInputGuideText : "XXXXXX",
        urlInputGuideTextSub : "XXXXXX",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '194000',
        image01: product_04_image_01,
        image02: product_04_image_02,
        imageDetail: product_04_image_01,
        categorySlug: "ai-algorithm",
        colors: ["white", "orange", "blue"],
        slug: "ai-algorithm",
        size: ["xl"],
        description: "고객의 긍부정 반응 파악!",
        descriptionTag : "#감성분류 #감정분류 #AI분류"
    },
]

const getAllProducts = () => products

const getProducts = (count) => {
    const max = products.length
    const min = 0
    // const start = Math.floor(Math.random() * (max - min) + min)
    return products.slice(min, min + max)
}

const getProductBySlug = (slug) => products.find(e => e.slug === slug)

const getCartItemsInfo = (cartItems) => {
    let res = []
    if (cartItems.length > 0) {
        cartItems.forEach(e => {
            let product = getProductBySlug(e.slug)
            res.push({
                ...e,
                product: product
            })
        })
    }
    // console.log(res)
    // console.log('sorted')
    // console.log(res.sort((a, b) => a.slug > b.slug ? 1 : (a.slug < b.slug ? -1 : 0)))
    return res.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))
}

const productData = {
    getAllProducts,
    getProducts,
    getProductBySlug,
    getCartItemsInfo
}

export default productData